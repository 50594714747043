import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Privacidade from '../../images/privacidade.png'

export const Section = styled.section`
  background-image: url(${Privacidade});
  height: 210px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 373px;
  }

  @media ${device.desktopLG} {
    height: 523px;
  }

  @media ${device.desktopXL} {
    height: 730px;
  }

  @media ${device.desktopXXXL} {
    height: 870px;
  }
`
