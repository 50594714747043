import React from 'react'

import * as S from './style'

const Acessivel = () => {
  return (
    <S.Section>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 d-flex justify-content-center mb-4 mb-md-4' />
        </div>
      </div>
    </S.Section>
  )
}

export default Acessivel
