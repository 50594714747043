import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Comecar from '../../images/comecar.png'

export const Section = styled.section`
  background-image: url(${Comecar});
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 330px;
  }

  @media ${device.desktopLG} {
    height: 420px;
  }

  @media ${device.desktopXL} {
    height: 740px;
  }

  @media ${device.desktopXXXL} {
    height: 800px;
  }
`
