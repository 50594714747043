import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Alcance from '../../images/aumente-o-alcance.png'

export const Section = styled.section`
  background-image: url(${Alcance});
  height: 210px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.desktopLG} {
    height: 520px;
  }

  @media ${device.desktopXL} {
    height: 690px;
  }

  @media ${device.desktopXXXL} {
    height: 840px;
  }
`
