import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Hero from '../../images/hero-bg.png'

export const Section = styled.section`
  background-image: url(${Hero});
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 710px;
  }

  @media ${device.desktopLG} {
    height: 980px;
  }

  @media ${device.desktopXL} {
    height: 1460px;
  }

  @media ${device.desktopXXXL} {
    height: 1730px;
  }
`
