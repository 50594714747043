import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Comece from '../../images/comece.png'

export const Section = styled.section`
  background-image: url(${Comece});
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 190px;
  }

  @media ${device.desktopLG} {
    height: 280px;
  }

  @media ${device.desktopXL} {
    height: 490px;
  }

  @media ${device.desktopXXXL} {
    height: 490px;
  }
 `
