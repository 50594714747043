import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import AvisosLegais from '../../images/avisos-legais.png'

export const Section = styled.section`
  background-image: url(${AvisosLegais});
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 120px;
  }

  @media ${device.desktopLG} {
    height: 170px;
  }

  @media ${device.desktopXL} {
    height: 220px;
  }
 `
