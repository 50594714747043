
import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import ComoFunciona from './sections/como-funciona/_index'
import AumenteOAlcance from './sections/aumente-o-alcance/_index'
import EquipamentoExtra from './sections/equipamento-extra/_index'
import Privacidade from './sections/privacidade/_index'
import Protecao from './sections/protecao/_index'
import Acessivel from './sections/acessivel/_index'
import ComecarEFacil from './sections/comecar/_index'
import Comece from './sections/comece/_index'
import AvisosLegais from './sections/avisos-legais/_index'

const TapToPayPhoneApple = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <ComoFunciona />
      <AumenteOAlcance />
      <EquipamentoExtra />
      <Privacidade />
      <Protecao />
      <Acessivel />
      <ComecarEFacil />
      <Comece />
      <AvisosLegais />
    </Layout>
  )
}

export default TapToPayPhoneApple
