import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Protecao from '../../images/protecao.png'

export const Section = styled.section`
  background-image: url(${Protecao});
  height: 270px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 474px;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    height: 614px;
  }

  @media ${device.desktopXL} {
    height: 930px;
  }

  @media ${device.desktopXXXL} {
    height: 1090px;
  }
`
