import styled from 'styled-components'
import EquipamentoExtra from '../../images/equipamento-extra.png'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${EquipamentoExtra});
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 414px;
  }

  @media ${device.desktopLG} {
    height: 480px;
  }

  @media ${device.desktopXL} {
    height: 740px;
  }

  @media ${device.desktopXXXL} {
    height: 860px;
  }
`
