import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import Acessivel from '../../images/acessivel.png'

export const Section = styled.section`
  background-image: url(${Acessivel});
  height: 210px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 380px;
  }

  @media ${device.desktopLG} {
    height: 520px;
  }

  @media ${device.desktopXL} {
    height: 740px;
  }

  @media ${device.desktopXXXL} {
    height: 850px;
  }
`
