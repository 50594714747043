import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import ComoFunciona from '../../images/como-funciona.png'

export const Section = styled.section`
  background-image: url(${ComoFunciona});
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.desktopLG} {
    height: 400px;
  }

  @media ${device.desktopXL} {
    height: 640px;
  }

  @media ${device.desktopXXXL} {
    height: 770px;
  }
`
